import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import BlogComponents from '@components/layout/BlogComponents/BlogComponents';

// === Content === //
import { BlogPageDTO } from '@DTO/BlogPageDTO';

// === Helpers === //
import { articleObject, breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface BlogPageProps {
  pageContext?: TemplatePageContextData;
}

const BlogPage = ({ pageContext }: BlogPageProps) => {
  const blogPage = BlogPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(blogPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
        <script type="application/ld+json">
          {JSON.stringify(articleObject(blogPage.components))}
        </script>
      </Helmet>
      <Layout
        metaData={blogPage.metaData}
        breadcrumbs={blogPage.breadcrumbs}
        stickedForm={blogPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={blogPage.langPath}
      >
        <BlogComponents components={blogPage.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default BlogPage;
